<template>
  <div
    class="d-flex justify-space-between align-items-center fill my-2"
    :class="{ 'my-4': !canEdit }"
  >
    <h3 class="flex-grow-0" :class="{ 'pt-4': canEdit }">{{ title }}</h3>
    <div
      class="d-flex flex-column flex-grow-1 pl-4"
      :class="{ 'justify-center': !canEdit }"
    >
      <v-icon
        v-if="canEdit"
        @click="$emit('edit')"
        class="align-self-end section-title-icon mb-2"
        >mdi-pencil</v-icon
      >
      <v-divider :class="{}"></v-divider>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      canEdit: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      classes() {
        return {};
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .section-title-icon {
    cursor: pointer;

    &:hover {
      color: $primary-color-2;
      transition: 0.3s ease-in;
    }
  }
</style>
