<template>
  <div>
    <p class="mt-3 mb-0">{{ label }}</p>
    <p class="my-0 black-1--text">
      <slot></slot>
    </p>
  </div>
</template>
<script>
  export default {
    props: {
      label: {
        type: String,
        required: true
      }
    }
  };
</script>
<style lang="scss" scoped></style>
