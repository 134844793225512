<template>
  <v-container>
    <title-header title="Informações" />

    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="7">
        <v-card class="mt-3 pt-7 pb-4 px-5">
          <section-title
            title="Solicitação"
            :canEdit="itsHistoric()"
            @edit="handleEditRequest"
          />
          <section-content label="Data de solicitação">
            {{ request.createdAt }}
          </section-content>
          <section-content v-if="request.scheduleDate" label="Data da coleta">
            {{ request.scheduleDate }} per&iacute;odo {{ request.shift }}
          </section-content>
          <section-content v-if="request.type" label="Tipo da solicitação">
            {{ request.type.description }}
          </section-content>
          <section-content
            v-if="request.situation"
            label="Status da solicitação"
            >{{ request.situation.description }}</section-content
          >
          <section-content v-if="request.crc" label="CRC pertencente">
            {{ request.crc.description }}
          </section-content>
          <section-content
            v-if="request.batch && request.batch.batchNumber"
            label="Lote"
          >
            {{ request.batch.batchNumber }}
          </section-content>

          <!-- Is PEV -->
          <div v-if="isPEVrequest">
            <section-title title="Parceiro" :canEdit="false" @edit="() => {}" />
            <section-content
              v-if="request.voluntaryDeliveryPoint.partner"
              label="Nome da empresa"
            >
              {{ request.voluntaryDeliveryPoint.partner.companyName }}
            </section-content>
            <section-content
              v-if="request.voluntaryDeliveryPoint.partner"
              label="CNPJ"
            >
              {{
                request.voluntaryDeliveryPoint.partner.cnpj | VMask(masks.cnpj)
              }}
            </section-content>

            <section-title
              title="Representante"
              :canEdit="false"
              @edit="() => {}"
            />
            <section-content
              v-if="request.voluntaryDeliveryPoint.partner"
              label="Nome completo"
            >
              {{ request.voluntaryDeliveryPoint.partner.agent.name }}
            </section-content>
            <section-content label="Telefone">
              {{
                parsePhone(
                  request.voluntaryDeliveryPoint.partner.agent.phones[0]
                )
              }}
            </section-content>
            <section-content label="Email">
              {{ request.voluntaryDeliveryPoint.partner.agent.email }}
            </section-content>
          </div>

          <!--Not PEV -->
          <div v-if="!isPEVrequest">
            <!-- PJ -->
            <div v-if="personOrEntity === 'PJ'">
              <section-title
                title="Pessoa Jurídica"
                :canEdit="false"
                @edit="() => {}"
              />
              <section-content label="Nome da empresa">
                {{ request.donator.donatorPJ.fantasyName }}
              </section-content>
              <section-content v-if="request.donator.donatorPJ" label="CNPJ">
                {{ request.donator.donatorPJ.cnpj | VMask(masks.cnpj) }}
              </section-content>
              <section-content label="Telefone">
                {{ parsePhone(request.donator.phones[0]) }}
              </section-content>
              <section-content label="Email">
                {{ request.donator.email }}
              </section-content>
              <section-content label="Endereço">
                {{ formatAddress(request.donator.address)[0] }}
                <br />
                {{ formatAddress(request.donator.address)[1] }}
              </section-content>

              <section-title
                title="Representante"
                :canEdit="false"
                @edit="() => {}"
              />
              <section-content label="Nome completo">
                {{ request.donator.donatorPJ.agent.name }}
              </section-content>
              <section-content label="Telefone">
                {{ parsePhone(request.donator.donatorPJ.agent.phones[0]) }}
              </section-content>
              <section-content label="Email">
                {{ request.donator.donatorPJ.agent.email }}
              </section-content>
            </div>

            <!-- PF -->
            <div v-if="personOrEntity === 'PF'">
              <section-title
                title="Pessoa Física"
                :canEdit="false"
                @edit="() => {}"
              />
              <section-content label="Nome completo">
                {{ request.donator.donatorPF.name }}
              </section-content>
              <section-content
                v-if="request.donator.donatorPF.cpf"
                label="CPF"
                >{{
                  request.donator.donatorPF.cpf | VMask(masks.cpf)
                }}</section-content
              >
              <section-content label="Email">
                {{ request.donator.email }}
              </section-content>
              <section-content label="Telefone">
                {{ parsePhone(request.donator.phones[0]) }}
              </section-content>
              <section-content label="Endereço">
                {{ formatAddress(request.donator.address)[0] }}
                <br />
                {{ formatAddress(request.donator.address)[1] }}
              </section-content>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <div v-if="isPEVrequest">
          <v-card class="mt-3 pt-7 pb-4 px-5">
            <section-title title="PEV" :canEdit="false" @edit="() => {}" />
            <section-content label="Informações">
              {{ request.voluntaryDeliveryPoint.name }}
              <br />
              {{ formatAddress(request.voluntaryDeliveryPoint.address)[0] }}
              <br />
              {{ formatAddress(request.voluntaryDeliveryPoint.address)[1] }}
            </section-content>
            <section-content label="Funcionamento">
              {{ request.voluntaryDeliveryPoint.businessHours }}
            </section-content>
          </v-card>
        </div>
        <v-card class="mt-3 pt-7 pb-4 px-5">
          <section-title
            title="Descrição"
            :canEdit="itsHistoric()"
            @edit="handleEditRequest"
          />
          <p>{{ request.description }}</p>
        </v-card>
        <v-card v-if="request.reasonSituation" class="mt-3 pt-7 pb-4 px-5">
          <section-title
            title="Justificativa"
            :canEdit="false"
            @edit="() => {}"
          />
          <p>{{ request.reasonSituation }}</p>
          <div class="d-flex flex-wrap">
            <v-card
              elevation="n-2"
              v-for="(picture, i) in request.pictures"
              :key="`img-container-${i}`"
              class="img-container mr-4 mt-4"
            >
              <core-image v-model="picture.hash" height="100%" />
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <update-request :state="requestState" @submit="submitForm" />
  </v-container>
</template>

<script>
  import { ptBR } from "date-fns/locale";
  import { format } from "date-fns";
  import { mapActions, mapGetters } from "vuex";

  import CoreImage from "@/modules/core/views/components/CoreImage";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import { parseAddress } from "@/modules/core/helpers/addressHelpers";
  import SectionTitle from "@/modules/account/user/views/components/SectionTitle";
  import SectionContent from "@/modules/account/user/views/components/SectionContent";
  import UpdateRequest from "@/modules/agenda/views/components/UpdateRequest";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  export default {
    name: "ViewRequest",

    data: () => ({
      request: {
        type: {},
        situation: {},
        crc: {},
        requester: {
          partner: {}
        }
      },
      isPEVrequest: false,
      personOrEntity: "",
      requestState: {
        dialog: false,
        form: {
          description: "",
          type: {
            id: ""
          }
        }
      }
    }),

    mixins: [MasksMixin],

    components: {
      SectionTitle,
      SectionContent,
      UpdateRequest,
      TitleHeader,
      CoreImage
    },

    async mounted() {
      await this.initRequest();
      this.checkIfItsPEV();
      this.isPersonOrEntity();
      this.itsHistoric();
    },

    methods: {
      ...mapActions("agenda", ["getRequestById", "updateRequestById"]),
      ...mapActions(["toggleSnackbar"]),

      async initRequest() {
        const response = await this.getRequestById(this.$route.params.id);

        this.request = this.formatUserData(response.data);
      },

      formatAddress(address) {
        return parseAddress(address);
      },

      handleEditRequest() {
        this.requestState.form.description = this.request.description;
        this.requestState.form.type.id = this.request.type.id;
        this.requestState.dialog = true;
      },

      checkIfItsPEV() {
        if (this.request.type.id === 1) {
          this.isPEVrequest = true;
        } else {
          this.isPEVrequest = false;
        }
      },

      isPersonOrEntity() {
        if (!this.isPEVrequest) {
          this.personOrEntity = this.request.donator.type;
        }
      },

      itsHistoric() {
        return this.$route.name === "ViewRequest";
      },

      formatUserData(request) {
        return {
          ...request,
          createdAt: format(new Date(request.createdAt), "dd/MM/yyyy - HH:mm", {
            locale: ptBR
          }),
          scheduleDate: request.scheduleDate
            ? format(new Date(request.scheduleDate), "dd/MM/yyyy", {
                locale: ptBR
              })
            : null,
          shift: request.shift ? request.shift.toLowerCase() : null
        };
      },

      firstStepSubmitHandler(requests) {
        this.formSchedule.collectionRequests = requests.map(request => {
          return { id: request.id };
        });
        this.state.currentStep++;
      },

      async secondStepSubmitHandler(e) {
        try {
          const response = await this.addCollectionSchedule({
            collectionRequests: this.formSchedule.collectionRequests,
            ...e
          });

          this.toggleSnackbar({
            text:
              response?.data?.message ?? "Funcionário cadastrado com sucesso",
            type: "success"
          });

          this.$router.push({ name: "users" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      async submitForm(formData) {
        try {
          const response = await this.updateRequestById({
            id: this.$route.params.id,
            payload: this.mountPayload(formData)
          });

          this.toggleSnackbar({
            text: response?.data?.message ?? "Solicitação alterada com sucesso",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro inesperado",
            type: "error"
          });
        } finally {
          await this.initRequest();
        }
      },

      mountPayload(data) {
        if (data.type.id === 1) {
          return {
            description: data.description
          };
        }

        return data;
      },
      mountUserRoles() {
        const role = {
          id: this.formUser.userRole.roleId
        };

        if (this.userIsManagerOperation) {
          return [
            {
              role,
              crc: {
                id: this.selectedCrc.id
              }
            }
          ];
        }
        if (this.formUserCrc.crcs.length && this.state.selectedRoleCanHaveCrc) {
          return this.formUserCrc.crcs.map(crcId => ({
            role,
            crc: {
              id: crcId
            }
          }));
        }
        return [{ role }];
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/components";

  @include form-register();

  .img-container {
    width: 190px;
    height: 190px;
  }
</style>
