<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <v-card class="modal">
        <validation-observer>
          <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
            <v-card-text class="py-7 px-7">
              <h3>Tipo de solicitação</h3>
              <v-row class="mt-n5" dense>
                <v-col cols="12" sm="12">
                  <radio-field
                    v-if="state.form.type.id === 1"
                    v-model="state.form.type.id"
                    type="genericText"
                    label="Tipo"
                    name="requestType"
                    :isRequired="true"
                    :items="PEVitem"
                  />
                  <radio-field
                    v-if="state.form.type.id !== 1"
                    v-model="state.form.type.id"
                    type="genericText"
                    label="Tipo"
                    name="requestType"
                    :isRequired="true"
                    :items="items"
                  />
                </v-col>
              </v-row>
              <h3>Informações</h3>
              <v-row class="mt-3" dense>
                <v-col cols="12">
                  <Field
                    v-model="state.form.description"
                    type="textarea"
                    label="Descrição"
                    name="description"
                    max="250"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 cancel-button black-3--text"
                :block="$isMobile"
                color="#ffffff"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid"
                color="secondary-color-1 white--text"
                class="ma-0 mt-1 next-step-button"
                :block="$isMobile"
                >Confirmar e Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";

  import Field from "@/modules/core/views/components/Field";
  import RadioField from "@/modules/core/views/components/RadioField";
  import { requestTypes } from "@/modules/agenda/enums/requestTypes.enum";

  export default {
    name: "UpdateRequest",

    props: {
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      Field,
      RadioField
    },
    data: () => ({
      items: [],
      PEVitem: {
        PEV: {
          description: "Pev",
          id: 1
        }
      }
    }),

    mounted() {
      const items = {
        ...requestTypes
      };
      delete items.PEV;
      this.items = Object.values(items);
      this.PEVitem = Object.values(this.PEVitem);
    },

    methods: {
      submitForm() {
        this.state.dialog = false;
        this.$emit("submit", {
          ...this.state.form
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
